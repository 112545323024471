import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { DietitianId } from '../dietitian';
import { FeatureId } from './feature';
import { PaymentMethod } from './payment_method';
import { StripePriceId } from './premium';
import {
  StripeProductId,
  StripeSubscriptionId,
  SubscriptionId,
  SubscriptionStatus,
} from './subscription';

export interface SubscriptionFeatureProps {
  stripeSubscriptionId?: StripeSubscriptionId;
  stripeProductId?: StripeProductId;
  stripePriceId?: StripePriceId;
  featureId: FeatureId;
  dietitianId: DietitianId;
  createdAt?: Date;
  updatedAt?: Date;
  status: SubscriptionStatus;
  periodStart: Date;
  periodEnd: Date;
  paymentMethod?: PaymentMethod;
}

export class SubscriptionFeature extends Entity<SubscriptionFeatureProps> {
  get subscriptionId(): SubscriptionId {
    return SubscriptionId.create(this.id);
  }

  get stripeSubscriptionId(): StripeSubscriptionId | undefined {
    return this.props.stripeSubscriptionId;
  }

  get stripeProductId(): StripeProductId | undefined {
    return this.props.stripeProductId;
  }

  get stripePriceId(): StripePriceId | undefined {
    return this.props.stripePriceId;
  }

  get featureId(): FeatureId {
    return this.props.featureId;
  }

  get dietitianId(): DietitianId {
    return this.props.dietitianId;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get status(): SubscriptionStatus {
    return this.props.status;
  }

  get periodStart(): Date {
    return this.props.periodStart;
  }

  get periodEnd(): Date {
    return this.props.periodEnd;
  }

  get paymentMethod(): PaymentMethod | undefined {
    return this.props.paymentMethod;
  }

  get isActive(): boolean {
    return [
      SubscriptionStatus.Active,
      SubscriptionStatus.Trialing,
      SubscriptionStatus.PendingCancellation,
    ].includes(this.status);
  }

  get isCancellable(): boolean {
    return [SubscriptionStatus.Active].includes(this.status);
  }

  get isPendingCancellation(): boolean {
    return this.status == SubscriptionStatus.PendingCancellation;
  }

  get isTrialing(): boolean {
    return this.status == SubscriptionStatus.Trialing;
  }

  get isUnpaid(): boolean {
    return this.status == SubscriptionStatus.Unpaid;
  }

  get isPastDue(): boolean {
    return this.status == SubscriptionStatus.PastDue;
  }

  get isRunning(): boolean {
    return this.periodEnd.getTime() > Date.now();
  }

  private constructor(props: SubscriptionFeatureProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: SubscriptionFeatureProps): SubscriptionFeature {
    return SubscriptionFeature.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: SubscriptionFeatureProps,
    id?: UniqueEntityID,
  ): SubscriptionFeature {
    return new SubscriptionFeature(props, id);
  }
}
