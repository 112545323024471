import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import StripePaginationResult from 'src/app/core/domain/stripe_pagination_result';
import { UnAuthorizedException } from 'src/app/core/logic/exception';

import {
  FeatureModel,
  StripeBalanceModel,
  SubscriptionModel,
} from '../../../../ui/dashboard/routes/account/routes/account-subscription/account-subscription.component';
import { FeatureRepository } from '../../repositories/feature.repository';
import { PremiumRepository } from '../../repositories/premium.repository';
import { SubscriptionFeatureRepository } from '../../repositories/subscription_feature.repository';
import {
  StripeProduct,
  SubscriptionRepository,
  SubscriptionSchema,
} from '../../repositories/subscription_repository';
import { Dietitian } from '../dietitian';
import { DietitianStateProvider } from '../dietitian_state_provider';
import { SubscriptionEventProvider } from '../events/subscription/subscription_event_provider';
import { SubscriptionCancelled } from '../events/subscription/subscription_events';
import { Feature } from './feature';
import { Invoice } from './invoice';
import { PaymentMethod } from './payment_method';
import { Subscription, SubscriptionProps } from './subscription';
import { SubscriptionFeature } from './subscription_feature';

@Injectable()
export class SubscriptionCommands {
  constructor(
    private repository: SubscriptionRepository,
    private featureRepository: FeatureRepository,
    private subscriptionFeatureRepository: SubscriptionFeatureRepository,
    private productRepository: PremiumRepository,
    private subscriptionEventProvider: SubscriptionEventProvider,
    private dietitianStateProvider: DietitianStateProvider,
    private toastr: ToastrService,
  ) {
    this.subscriptionEventProvider.events$.subscribe((event) => {
      if (event instanceof SubscriptionCancelled) {
        this.toastr.success('Abonnement annulé');
      }
    });
  }

  getRunningSubscriptions(): Promise<Subscription | undefined> {
    if (this.dietitianStateProvider.state.entity === undefined) {
      throw new UnAuthorizedException();
    }

    return this.repository.findDietitianRunningSubscription(
      this.dietitianStateProvider.state.entity.id.toString(),
    );
  }

  async getLatestSubscription(
    dietitian: Dietitian,
  ): Promise<SubscriptionModel | undefined> {
    if (!dietitian) {
      throw new UnAuthorizedException();
    }

    const subscription = await this.repository.findDietitianLatestSubscription(
      dietitian.id.toString(),
    );

    if (subscription && subscription.stripeSubscriptionId) {
      const result: SubscriptionModel = {
        subscription: await this.repository.findDietitianLatestSubscription(
          dietitian.id.toString(),
        ),
        prices: await this.repository.getStripeProductBySubscription(
          subscription.stripeSubscriptionId.id.toString(),
        ),
      };
      return result;
    }

    return undefined;
  }

  createSubscriptionCheckoutSession(
    priceId: string,
    successUrl: string,
    cancelUrl: string,
  ): Promise<string> {
    if (this.dietitianStateProvider.state.entity === undefined) {
      throw new UnAuthorizedException();
    }

    return this.repository.createSubscriptionCheckoutSession(
      priceId,
      successUrl,
      cancelUrl,
    );
  }

  createUpdatePaymentMethodCheckoutSession(
    successUrl: string,
    cancelUrl: string,
  ): Promise<string> {
    if (this.dietitianStateProvider.state.entity === undefined) {
      throw new UnAuthorizedException();
    }

    return this.repository.createUpdatePaymentMethodCheckoutSession(
      successUrl,
      cancelUrl,
    );
  }

  listenSubscription(
    subscriptionId: string,
  ): Observable<SubscriptionSchema | undefined> {
    return this.repository.listenSubscription(subscriptionId);
  }

  async cancelSubscriptions(): Promise<void> {
    if (this.dietitianStateProvider.state.entity === undefined) {
      throw new UnAuthorizedException();
    }

    await this.repository.cancelSubscriptions();

    this.subscriptionEventProvider.dispatch(
      new SubscriptionCancelled(Subscription.create({} as SubscriptionProps)),
    );
  }

  async getInvoices(
    pageSize: number,
    startingAfter: string | null,
    endingBefore: string | null,
  ): Promise<StripePaginationResult<Invoice>> {
    if (this.dietitianStateProvider.state.entity === undefined) {
      throw new UnAuthorizedException();
    }

    return this.repository.getInvoices(pageSize, startingAfter, endingBefore);
  }

  async getBalances(): Promise<StripeBalanceModel[]> {
    return this.repository.getBalances();
  }

  async getFeatures(): Promise<Feature[]> {
    return this.featureRepository.findAllActive();
  }

  async getStripeProduct(stripeProductId: string): Promise<StripeProduct> {
    return this.repository.getStripeProduct(stripeProductId);
  }

  async getRunningSubscriptionFeature(
    dietitian: Dietitian,
    feature: Feature,
  ): Promise<SubscriptionFeature | undefined> {
    if (!dietitian) {
      throw new UnAuthorizedException();
    }

    return this.subscriptionFeatureRepository.findDietitianFeatureRunningSubscriptionFeature(
      dietitian.id.toString(),
      feature.id.toString(),
    );
  }

  async cancelSubscriptionFeature(subscriptionFeature: SubscriptionFeature) {
    await this.repository.cancelSubscriptionFeature(
      subscriptionFeature.stripeSubscriptionId?.id.toString(),
    );

    this.subscriptionEventProvider.dispatch(
      new SubscriptionCancelled(Subscription.create({} as SubscriptionProps)),
    );
  }

  async updatePremium(stripeSubscriptionId: string, stripePriceId: string) {
    const premiums = await this.productRepository.findAllActive();
    const expectedPrices = premiums.filter(
      (p) => p.stripePriceId.id.toString() !== stripePriceId,
    );
    if (expectedPrices.length > 0) {
      await this.repository.updateSubscriptionPlan(
        stripeSubscriptionId,
        expectedPrices[0].stripePriceId.id.toString(),
      );
    }
  }

  async getPaymentMethods(): Promise<PaymentMethod[]> {
    return this.repository.getPaymentMethods();
  }

  async subscribeFeature(featurePriceId: string, coupon: string | undefined) {
    return this.repository.subscribeFeature(featurePriceId, coupon);
  }

  async defaultPaymentMethod(paymentMethodId: string) {
    return this.repository.defaultPaymentMethod(paymentMethodId);
  }

  async getFeatureModel(dietitian: Dietitian, code: string) {
    const result = {} as FeatureModel;
    const features = await this.featureRepository.findAllActive();
    const expectedFeatures = features.filter((f) => f.code === code);
    if (expectedFeatures && expectedFeatures.length === 1) {
      result.feature = expectedFeatures[0];
      result.stripeProduct = await this.getStripeProduct(
        expectedFeatures[0].stripeProductId.id.toString(),
      );
      result.subscription = await this.getRunningSubscriptionFeature(
        dietitian,
        expectedFeatures[0],
      );
    }
    return result;
  }
}
